@font-face {
    font-family: 'libre-franklin';
    src: url('../fonts/LibreFranklin-Regular.woff2') format('woff2'),
        url('../fonts/LibreFranklin-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'libre-franklin';
    src: url('../fonts/LibreFranklin-Medium.woff2') format('woff2'),
        url('../fonts/LibreFranklin-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'libre-franklin';
    src: url('../fonts/LibreFranklin-Bold.woff2') format('woff2'),
        url('../fonts/LibreFranklin-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'libre-franklin';
    src: url('../fonts/LibreFranklin-Black.woff2') format('woff2'),
        url('../fonts/LibreFranklin-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

html {
	height: 100%;
}

html, body {
    margin: 0;
    padding: 0;
}

html {
    font-family: 'libre-franklin';
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

body {
	padding: 20px;
	width: 90%;
	max-width: 750px;
}

a {
	text-decoration: none;
}

attr {
	border-bottom: 1px solid dotted;
	cursor: help;
}

// Light mode

html {
	background: #fff;
	color: #000;
}

html,
a {
	color: #000;
}

a {
	background: yellow;

	&:hover {
		color: yellow;
		background: #000;
	}
}

// Dark mode

@media (prefers-color-scheme: dark) {
	html {
		background: #444444;
		color: #ffea00;
	}

	html,
	a {
		color: yellow;
	}

	a {
		background: #232323;

		&:hover {
			color: #fff;
			background: #000;
		}
	}
}

// Intro

.intro {
	font-size: 20px;
	letter-spacing: .6px;

	> p:nth-child(1) {
		font-size: 24px;
		line-height: 33px;
	}

	> p > span {
		display: block;
		overflow: hidden;

		+ span {
			margin-top: 20px;
		}

		&:nth-child(1) {
		  margin-bottom: 10px;
		}

		> span {
			display: block;
			transform: translate(0, 100%);
			opacity: 0;
			animation: .4s showText;
			animation-fill-mode: forwards; 
		}
	}

	> p > span:nth-child(1) > span { animation-delay: 0.2s; }
	> p > span:nth-child(2) > span { animation-delay: 0.3s; }
	> p > span:nth-child(3) > span { animation-delay: 0.4s; }
	> p > span:nth-child(4) > span { animation-delay: 0.5s; }
}

@keyframes showText {
	0% {
		transform: translate(0, 100%);
		opacity: 0;
	}
	100% {
		transform: translate(0);
		opacity: 1;
	}
}